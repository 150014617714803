// Bootstrap Framework
import jquery, { ajax } from "jquery";
window.$ = window.jQuery = jquery; //Global
import "./scss/index.scss";
import "popper.js";
import "bootstrap";
import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import Handlebars from "handlebars";
window.Handlebars = Handlebars;
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timegrid from '@fullcalendar/timegrid';
import itLocale from '@fullcalendar/core/locales/it';
import Tooltip from 'tooltip-js';

$(".main-slider").slick({
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  speed:3000
});

$(".news-slider").slick({
  dots: true,
  infinite: true,
  speed: 3000,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
});

// When the user scrolls the page, execute myFunction
window.onscroll = function () {
  myFunction();
};

// Get the header
var header = document.getElementById("myHeader");

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}


$(function () {

    //#region Calendario Corsi

    var elementCalendarioCorsi = document.getElementById("divCalendarioCorsi");
    if (elementCalendarioCorsi) {

        var currentLangCode = 'IT';
        //var source = $("#cal-template").html();
        //var template = Handlebars.compile(source);

        var source_events = $("#events-template").html();
        var template_events = Handlebars.compile(source_events);

        $(document).on('click', '#btn-back-calendar', function () {
            $('#calendar').removeClass('d-none');
            $('#event-detail').empty();
            $('#events-detail').addClass('d-none');
            return false;
        });


        var calendarEl = document.getElementById('calendar');
        var calendar = new Calendar(calendarEl,
            {
                plugins: [dayGridPlugin, listPlugin, timegrid],
                initialView: 'dayGridMonth',
                locale: itLocale,
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth'
                },
                eventSources: [
                    {
                        url: '../handlers/events.ashx',
                        type: 'GET',
                        data: {
                            'lang': currentLangCode
                        },
                        error: function (e) {
                            alert('there was an error while fetching events!');
                        },
                        success: function (data) {

                            console.log(data);

                            $('#events').empty();
                            $('#events').append(template_events({ rows: data }));
                        },
                        color: '#00130'
                    }
                ],
                eventClick: function (info) {
                    //$('#calendar').addClass('d-none');
                    //$('#event-detail').empty().append($('[id*=data-' + info.event.id + ']').clone());
                    //$('#events-detail').removeClass('d-none');
                },
                buttonIcons: true,
                weekNumbers: false,
                editable: true,
                displayEventTime: true,
                displayEventEnd: true,
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }
            });

        calendar.render();
    }

    //#endregion

});